









































































































import {
  defineComponent,
  onMounted,
  computed,
  ref,
} from '@vue/composition-api';
import { useGlobal } from '@/modules/global';

export default defineComponent({
  name: 'WelcomeModal',
  setup() {
    const { getSettings, saveSettings, userSettings } = useGlobal();

    const readAndUnderstood = ref<boolean>();
    const loading = ref<boolean>();
    const fetchingSettings = ref<boolean>();

    onMounted(async () => {
      fetchingSettings.value = true;
      await getSettings();
      fetchingSettings.value = false;
    });

    const showWelcome = computed(() => {
      let show = false;

      if (!fetchingSettings.value && userSettings?.value) {
        show = !userSettings?.value?.WebSettings?.AcceptedTermsAndConditions;
      }

      return show;
    });

    const submit = async () => {
      loading.value = true;
      if (userSettings?.value) {
        userSettings.value.WebSettings = {
          AcceptedTermsAndConditions: true,
          IsUsingWeb: true,
        };

        await saveSettings();
      }
      loading.value = false;
    };

    return {
      submit,
      showWelcome,
      loading,
      readAndUnderstood,
      termsUrl: process.env.VUE_APP_TERMS_URL,
      documentationUrl: process.env.VUE_APP_DOCUMENTATION_URL,
    };
  },
});
